<div class="auth-modal">

  <button type="button" class="auth-modal__close-btn"></button>

  <h2 class="auth-modal__form--title">Registration</h2>

  <form class="auth-modal__form auth-form-sign-up" name="userSignUp">

    <input class="auth-modal__input" type="text" placeholder="First name" name="firstName" minlength="2" required />

    <input class="auth-modal__input" type="text" placeholder="Second name" name="secondName" minlength="2" required />

    <input class="auth-modal__input" type="email" placeholder="E-mail" name="email" minlength="5" required />

    <input class="auth-modal__input" type="tel" placeholder="Phone: +3809********" name="phone" pattern="[\+]\d{12}"
      required />

    <input class="auth-modal__input" type="password" placeholder="Password" name="password" minlength="6" required />

    <input class="auth-modal__input-repeat" type="password" placeholder="Repeat password" name="passwordRepeat"
      minlength="6" required />

    <button type="submit" class="auth-modal__submit-btn">Sign Up</button>
  </form>
</div>