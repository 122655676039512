<div class="selected-goods__inner">
  <span class="modal__icon--close close__favorites"></span>
  <div class="selected-goods">
    <h3 class="selected-goods__title icon--heart-on">Favourites</h3>

    <ul class="selected-goods__list">
      {{#each this}}
      <li class="selected-goods__item" data-id="{{id}}">
        <img class="selected-goods__item-img" src="{{mainImg}}" alt="{{name}}" width="220" height="180">
        <div class="selected-goods__item-block__info">
          <h4 class="selected-goods__item-title">{{name}}</h4>
          <span class="selected-goods__item-price">{{price}} uah</span>
          <p class="selected-goods__item-desc">{{description}}</p>
        </div>
        <span class="selected-goods__item-icon">
          <span class="selected-goods__icon-text">Delete <span class="selected-goods__icon-text--hidden">from
              favourites</span></span>
        </span>
      </li>
      {{/each}}
    </ul>
  </div>
</div>