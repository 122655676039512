<div class="auth-modal">

  <button type="button" class="auth-modal__close-btn"></button>

  <h2 class="auth-modal__form--title">Login</h2>

  <form class="auth-modal__form auth-form-sign-in" name="userSignIn">

    <input class="auth-modal__input" type="email" placeholder="E-mail" name="email" minlength="5" required />

    <input class="auth-modal__input" type="password" placeholder="Password" name="password" minlength="6" required />

    <button type="submit" class="auth-modal__submit-btn">Sign In</button>

    <p class="auth-modal__and-text">or</p>

    <button class="auth-modal__submit-btn-reg js-go-to-register" type="button">Sign Up</button>
  </form>
</div>