<li class="categories-filter__item">
    <button data-category="property" class="categories-filter__item--btn">Property</button>
</li>
<li class="categories-filter__item">
    <button data-category="transport" class="categories-filter__item--btn">Transport</button>
</li>
<li class="categories-filter__item">
    <button data-category="work" class="categories-filter__item--btn">Work</button>
</li>
<li class="categories-filter__item">
    <button data-category="electronics" class="categories-filter__item--btn">Electronics</button>
</li>
<li class="categories-filter__item">
    <button data-category="businessAndServices" class="categories-filter__item--btn">
        Business and services
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="recreationAndSport" class="categories-filter__item--btn">
        Recreation and sport
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="free" class="categories-filter__item--btn">
        Free
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="trade" class="categories-filter__item--btn">Trade</button>
</li>