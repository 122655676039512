<div class="category-list {{category}}-wrapper ">
    <div class="category-info">
        <span class="category-name">{{nameCategory}}</span>
        <a class="view-all " data-category='{{category}}'>Load all</a>
    </div>
    <p class="category-description">{{descriptionCategory}}.</p>
    <ul class="things-list {{category}}-list show-all">
    </ul>
    <button class="slide-prev"></button>
    <button class="slide-next"></button>
</div>